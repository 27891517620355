import {LoadableStateModel} from "../../core/models/loadable.model";
import {PageableStateModel} from "../../core/models/pageable.model";
import {
  DEFAULT_FETCH_QUERY_PARAMS_FIRMWARE_ASSIGN,
  DEFAULT_FETCH_QUERY_PARAMS_FIRMWARE_UPLOAD,
  FirmwareAssignmentInfoDTO,
  FirmwareDTO,
  IFirmwareFetchQueryParams
} from "./firmware.model";
import {Action, Selector, State, StateContext} from "@ngxs/store";
import {Injectable} from "@angular/core";
import {StoreState} from "../store.state";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {TranslateService} from "@ngx-translate/core";
import {ServerHealthyService} from "../../core/services/server-healthy.service";
import {ErrorMessageService} from "../../core/services/error-message.service";
import {FirmwareActions} from "./firmware.action";
import {FirmwareService} from "./firmware.service";
import {first} from "rxjs/operators";
import {EMPTY, Observable} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';

const DEFAULT_PAGINATION: PageableStateModel = {total: 0, page: 1, size: 25};

export interface FirmwareStateModel extends LoadableStateModel {
  availableFirmwares: FirmwareDTO[];
  assignedFirmwares: FirmwareAssignmentInfoDTO[];
  fetchQueryParams?: IFirmwareFetchQueryParams;
  fetchQueryParamsAssign?: IFirmwareFetchQueryParams;
  firmwareUploadStatus: boolean;
  availableFirmwaresPagination: PageableStateModel;
  assignedFirmwaresPagination: PageableStateModel;
}

@State<FirmwareStateModel>({
  name: 'firmwares',
  defaults: {
    firmwareUploadStatus: false,
    availableFirmwares: [],
    assignedFirmwares: [],
    availableFirmwaresPagination: {...DEFAULT_PAGINATION},
    assignedFirmwaresPagination: {...DEFAULT_PAGINATION},
    loading: false,
    fetchQueryParams: DEFAULT_FETCH_QUERY_PARAMS_FIRMWARE_UPLOAD,
    fetchQueryParamsAssign: DEFAULT_FETCH_QUERY_PARAMS_FIRMWARE_ASSIGN
  }
})
@Injectable({providedIn: 'root'})
export class FirmwareState extends StoreState<FirmwareStateModel> {
  constructor(
    protected notificationService: NzNotificationService,
    protected translateService: TranslateService,
    protected serverHealthyService: ServerHealthyService,
    protected errorMessageService: ErrorMessageService,
    protected firmwareService: FirmwareService,
    protected nzNotificationService: NzNotificationService
  ) {
    super(notificationService, translateService, serverHealthyService, errorMessageService);
  }

  @Selector()
  static loading(state: FirmwareStateModel) {
    return state.loading;
  }


  @Selector()
  static firmwareUploadStatus(state: FirmwareStateModel) {
    return state.firmwareUploadStatus;
  }

  @Selector()
  static availableFirmwares(state: FirmwareStateModel) {
    return state.availableFirmwares;
  }

  @Selector()
  static assignedFirmwares(state: FirmwareStateModel) {
    return state.assignedFirmwares;
  }

  @Selector()
  static availableFirmwaresPagination(state: FirmwareStateModel) {
    return state.availableFirmwaresPagination;
  }

  @Selector()
  static assignedFirmwaresPagination(state: FirmwareStateModel) {
    return state.assignedFirmwaresPagination;
  }

  @Action(FirmwareActions.FetchAvailableFirmwares)
  fetchAvailableFirmwares(ctx: StateContext<FirmwareStateModel>, {
    page,
    params
  }: FirmwareActions.FetchAvailableFirmwares) {
    const {availableFirmwaresPagination} = ctx.getState();
    const updatedPagination = {...availableFirmwaresPagination, page};
    return this.fetchAvailableFirmwaresData(ctx, updatedPagination, params);
  }

  @Action(FirmwareActions.FetchAssignedFirmwares)
  fetchAssignedFirmwares(ctx: StateContext<FirmwareStateModel>, {
    page,
    params
  }: FirmwareActions.FetchAssignedFirmwares) {
    const {assignedFirmwaresPagination} = ctx.getState();
    const updatedPagination = {...assignedFirmwaresPagination, page};
    return this.fetchAssignedFirmwaresData(ctx, updatedPagination, params);
  }

  @Action(FirmwareActions.AssignFirmware)
  assignFirmware(ctx: StateContext<FirmwareStateModel>, {payload}: FirmwareActions.AssignFirmware) {
    ctx.patchState({loading: true});
    this.firmwareService.assignFirmware(payload).pipe(first()).subscribe(
      () => {
        ctx.patchState({loading: false});
        ctx.dispatch(new FirmwareActions.FetchAssignedFirmwares(1, DEFAULT_FETCH_QUERY_PARAMS_FIRMWARE_ASSIGN));
        this.nzNotificationService.success(
          this.translateService.instant('FIRMWARE.ASSIGN_SUCCESS'),
          'FIRMWARE.ASSIGN_SUCCESS'
        );
      },
      (e) => this.errorHandler(e, ctx)
    );
  }

  @Action(FirmwareActions.AvailableFirmwaresPageSizeChanged)
  availableFirmwaresPageSizeChanged(ctx: StateContext<FirmwareStateModel>, {
    size,
    params
  }: FirmwareActions.AvailableFirmwaresPageSizeChanged) {
    const {availableFirmwaresPagination} = ctx.getState();
    const updatedPagination = {...availableFirmwaresPagination, page: 1, size};
    ctx.patchState({availableFirmwaresPagination: updatedPagination});
    return this.fetchAvailableFirmwaresData(ctx, updatedPagination, params);
  }

  @Action(FirmwareActions.AssignedFirmwaresPageSizeChanged)
  assignedFirmwaresPageSizeChanged(ctx: StateContext<FirmwareStateModel>, {
    size,
    params
  }: FirmwareActions.AssignedFirmwaresPageSizeChanged) {
    const {assignedFirmwaresPagination} = ctx.getState();
    const updatedPagination = {...assignedFirmwaresPagination, page: 1, size};
    ctx.patchState({assignedFirmwaresPagination: updatedPagination});
    return this.fetchAssignedFirmwaresData(ctx, updatedPagination, params);
  }

  @Action(FirmwareActions.UploadFirmware)
  uploadFirmware(ctx: StateContext<FirmwareStateModel>, {firmware}: FirmwareActions.UploadFirmware) {
    ctx.patchState({loading: true});
    this.firmwareService.uploadFirmware(firmware).pipe(first()).subscribe(
      () => {
        ctx.patchState({loading: false, firmwareUploadStatus: true});
        ctx.dispatch(new FirmwareActions.FetchAvailableFirmwares(1, DEFAULT_FETCH_QUERY_PARAMS_FIRMWARE_UPLOAD));
        this.notificationService.success('Success', `${firmware.file.name} File uploaded successfully`);
      },
      (e) => this.errorHandler(e, ctx)
    );
  }

  @Action(FirmwareActions.DeleteFirmware)
  deleteFirmware(ctx: StateContext<FirmwareStateModel>, {id}: FirmwareActions.DeleteFirmware) {
    ctx.patchState({loading: true});
    this.firmwareService.deleteFirmware(id).pipe(first()).subscribe(
      () => {
        ctx.patchState({loading: false});
        ctx.dispatch(new FirmwareActions.FetchAvailableFirmwares(1, DEFAULT_FETCH_QUERY_PARAMS_FIRMWARE_UPLOAD));
      },
      (e) => this.errorHandler(e, ctx)
    );
  }

  @Action(FirmwareActions.UnAssignFirmware)
  unAssignFirmware(ctx: StateContext<FirmwareStateModel>, {payload}: FirmwareActions.UnAssignFirmware) {
    ctx.patchState({loading: true});
    this.firmwareService.unAssignFirmware(payload).pipe(first()).subscribe(
      () => {
        ctx.patchState({loading: false});
        ctx.dispatch(new FirmwareActions.FetchAssignedFirmwares(1, DEFAULT_FETCH_QUERY_PARAMS_FIRMWARE_ASSIGN));
        this.nzNotificationService.success(
          this.translateService.instant('FIRMWARE.UNASSIGN_SUCCESS'),
          'FIRMWARE.UNASSIGN_SUCCESS'
        );
      },
      (e) => this.errorHandler(e, ctx)
    );
  }

  @Action(FirmwareActions.ForceBurnFirmware)
  forceBurnFirmware(ctx: StateContext<FirmwareStateModel>, {payload}: FirmwareActions.ForceBurnFirmware) {
    ctx.patchState({loading: true});
    this.firmwareService.forceBurnFirmware(payload).pipe(first()).subscribe(
      () => {
        ctx.patchState({loading: false});
        this.nzNotificationService.success(
          this.translateService.instant('messages.success'),
          this.translateService.instant('messages.force-burn-firmware')
        );
      },
      (e) => this.errorHandler(e, ctx)
    );
  }


  private fetchAvailableFirmwaresData(
    ctx: StateContext<FirmwareStateModel>,
    pagination: PageableStateModel,
    params: IFirmwareFetchQueryParams
  ) {
    return this.fetchData(
      ctx,
      pagination,
      params,
      (pagination, queryParams) => this.firmwareService.fetchAllFirmware(pagination, queryParams),
      this.updateStateAfterFetchAvailableFirmwares
    );
  }

  private fetchAssignedFirmwaresData(
    ctx: StateContext<FirmwareStateModel>,
    pagination: PageableStateModel,
    params: IFirmwareFetchQueryParams
  ) {
    return this.fetchData(
      ctx,
      pagination,
      params,
      (pagination, queryParams) => this.firmwareService.fetchAllAssigneeFirmware(pagination, queryParams),
      this.updateStateAfterFetchAssignedFirmwares
    );
  }

  private fetchData<T>(
    ctx: StateContext<FirmwareStateModel>,
    pagination: PageableStateModel,
    params: IFirmwareFetchQueryParams,
    fetchMethod: (pagination: PageableStateModel, params: IFirmwareFetchQueryParams) => Observable<T>,
    stateUpdater: (
      ctx: StateContext<FirmwareStateModel>,
      response: T,
      pagination: PageableStateModel,
      params: IFirmwareFetchQueryParams
    ) => void
  ) {

    ctx.patchState({loading: true});

    return fetchMethod(pagination, params).pipe(
      first(),
      tap(response => {
        ctx.patchState({loading: false});
        stateUpdater(ctx, response, pagination, params);
      }),
      catchError((e) => {
        this.errorHandler(e, ctx);
        return EMPTY;
      })
    );
  }

  private updateStateAfterFetchAvailableFirmwares(
    ctx: StateContext<FirmwareStateModel>,
    response: { firmwares: FirmwareDTO[]; total: number },
    pagination: PageableStateModel,
    params: IFirmwareFetchQueryParams
  ) {
    const {fetchQueryParams, availableFirmwaresPagination} = ctx.getState();
    ctx.patchState({
      fetchQueryParams: {...fetchQueryParams, ...params},
      firmwareUploadStatus: false,
      availableFirmwares: response.firmwares,
      availableFirmwaresPagination: {
        ...availableFirmwaresPagination,
        page: pagination.page,
        total: response.total,
        size: pagination.size
      }
    });
  }

  private updateStateAfterFetchAssignedFirmwares(
    ctx: StateContext<FirmwareStateModel>,
    response: { firmwaresAssignment: FirmwareAssignmentInfoDTO[]; total: number },
    pagination: PageableStateModel,
    params: IFirmwareFetchQueryParams
  ) {
    const {fetchQueryParams, assignedFirmwaresPagination} = ctx.getState();
    ctx.patchState({
      fetchQueryParams: {...fetchQueryParams, ...params},
      assignedFirmwares: response.firmwaresAssignment,
      assignedFirmwaresPagination: {
        ...assignedFirmwaresPagination,
        page: pagination.page,
        total: response.total,
        size: pagination.size
      }
    });
  }
}

